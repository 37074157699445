import React from "react"
import { useNavigate } from "react-router"

export function Page() {
  const navigate = useNavigate()

  React.useEffect(() => {
    navigate(`/agents`)
  }, [])

  return null
}

export default Page
