import { FullscreenLoadingOverlay } from "@/components/fullscreen-loading-overlay"
import { useSession } from "@/context/auth"
import React from "react"
import { Outlet, useLocation, useNavigate } from "react-router"
import { AppLayout } from "./app-layout"
import { AuthLayout } from "./auth-layout"
import { ErrorLayout } from "./error-layout"

function RedirectToLogin() {
  const navigate = useNavigate()
  React.useEffect(() => {
    navigate("/auth/login")
  }, [])
  return <FullscreenLoadingOverlay />
}

export function Layout() {
  const location = useLocation()
  const session = useSession()

  if (
    location.pathname.startsWith("/privacy-policy") ||
    location.pathname.startsWith("/terms-of-service") ||
    location.pathname.startsWith("/auth/callback") ||
    location.pathname.startsWith("/onboarding")
  ) {
    return <Outlet />
  } else if (location.pathname.startsWith("/auth")) {
    return (
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    )
  } else if (location.pathname.startsWith("/errors")) {
    return (
      <ErrorLayout>
        <Outlet />
      </ErrorLayout>
    )
  } else {
    if (session === null) {
      return <RedirectToLogin />
    }
    return <AppLayout />
  }
}
