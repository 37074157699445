import { api } from "@/api"
import { useSession } from "@/context/auth"
import { QueryKeys } from "@/lib/query-keys"
import { useQueryClient } from "@tanstack/react-query"
import React, { useEffect } from "react"

export function useApiPrefetch() {
  const session = useSession()
  const queryClient = useQueryClient()

  const isPrefetching = React.useRef(false)

  const [prefetchComplete, setPrefetchComplete] = React.useState(false)

  useEffect(() => {
    if (!session) {
      queryClient.clear()
      isPrefetching.current = false
      return
    }
    async function act() {
      if (isPrefetching.current) {
        return
      }
      isPrefetching.current = true

      queryClient.prefetchQuery({
        queryKey: QueryKeys.agents.context(),
        queryFn: () => api.agents.getAgentsContext.query(),
      })

      const agents = await queryClient.fetchQuery({
        queryKey: QueryKeys.agents.agentsList(),
        queryFn: () => api.agents.listAllAgents.query(),
      })

      if (agents.length === 0) {
        setPrefetchComplete(true)
        return
      }

      // For each agent in the list, prefetch details and by-slug queries
      await Promise.all(
        agents.map((agent) =>
          Promise.all([
            queryClient.prefetchQuery({
              queryKey: QueryKeys.agents.agentDetailById(agent.id),
              queryFn: () => api.agents.getDetail.query({ agentId: agent.id }),
            }),
            // hydrate this data using the agent data we already fetched
            queryClient.prefetchQuery({
              queryKey: QueryKeys.agents.agentBySlug(agent.slug),
              queryFn: () => agents.find((x) => x.slug === agent.slug) ?? null,
            }),
          ]),
        ),
      )

      // We have the agents, that is good enough for now
      setPrefetchComplete(true)

      //// For each agent in the list, starred and recent conversations
      //for (const agent of agents) {
      //  // FETCH agent conversations list (doesn't have messages)
      //  const conversations = await queryClient.fetchQuery({
      //    queryKey: QueryKeys.agents.conversationsListByAgentId(agent.id),
      //    queryFn: () => trpc.agents.listConversationsByAgentId.query({ agentId: agent.id }),
      //  })
      //
      //  // Prefetch starred and the most recent not starred conversations
      //  const starredConversations = conversations.filter((x) => x.starred).slice(0, 5)
      //  const recentConversations = conversations.filter((x) => !x.starred).slice(0, 5)
      //
      //  const targetConversations = [...starredConversations, ...recentConversations]
      //  if (targetConversations.length === 0) {
      //    return
      //  }
      //  await Promise.all(
      //    targetConversations.map((conversation) =>
      //      queryClient.prefetchQuery({
      //        queryKey: QueryKeys.agents.conversationDetailById(conversation.id),
      //        queryFn: () =>
      //          trpc.agents.getConversationById.query({ conversationId: conversation.id }),
      //      }),
      //    ),
      //  )
      //}
    }
    act()
  }, [queryClient, session])

  return prefetchComplete
}
