import { createBrowserRouter } from "./sentry"

import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister"
import { QueryClient, type QueryKey } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router/dom"
import { ThemeProvider } from "./components/theme-provider.tsx"
import { Toaster } from "./components/ui/sonner.tsx"
import { TooltipProvider } from "./components/ui/tooltip.tsx"
import { AuthorizationProvider } from "./context/auth.tsx"
import "./global.css"
import { routes } from "./routes.tsx"

const router = createBrowserRouter(routes)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 1 hour
      gcTime: 1000 * 60 * 90, // 90 minutes
      experimental_prefetchInRender: true,
    },
  },
})

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
        <TooltipProvider>
          <AuthorizationProvider>
            <RouterProvider router={router} />
          </AuthorizationProvider>
        </TooltipProvider>
      </ThemeProvider>
    </PersistQueryClientProvider>
    <Toaster />
  </React.StrictMode>,
)
