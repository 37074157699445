import React from "react"
import { Layout } from "./layouts/layout.tsx"
import Dashboard from "./pages/dashboard.tsx"

const AgentChat = React.lazy(() => import("./pages/agent-chat"))
const AgentList = React.lazy(() => import("./pages/agents-list"))
const AgentsManageContext = React.lazy(() => import("./pages/agents-manage-context"))
const AgentsManageCreate = React.lazy(() => import("./pages/agents-manage-create"))
const AgentsManageEdit = React.lazy(() => import("./pages/agents-manage-edit"))
const AgentsManageList = React.lazy(() => import("./pages/agents-manage-list"))
const AgentsManageReports = React.lazy(() => import("./pages/agents-manage-reports"))
const AgentsManageReportDetail = React.lazy(() => import("./pages/agents-manage-report-detail"))
const AgentsManageKnowledgeList = React.lazy(() => import("./pages/agents-manage-knowledge-list"))
const AgentsManageKnowledgeCreate = React.lazy(
  () => import("./pages/agents-manage-knowledge-create"),
)
const AgentsManageKnowledgeEdit = React.lazy(() => import("./pages/agents-manage-knowledge-edit"))
const DocumentsList = React.lazy(() => import("./pages/documents-list"))
const DocumentDetail = React.lazy(() => import("./pages/document-detail"))
const DynamicTablesCreate = React.lazy(() => import("./pages/dynamic-tables-create"))
const DynamicTablesDetail = React.lazy(() => import("./pages/dynamic-tables-detail"))
const DynamicTablesList = React.lazy(() => import("./pages/dynamic-tables-list"))
const AuthCallback = React.lazy(() => import("./pages/auth-callback-page"))
const AuthCode = React.lazy(() => import("./pages/auth-code-page"))
const AuthLogin = React.lazy(() => import("./pages/auth-login-page"))
const DocsReleaseNotes = React.lazy(() => import("./pages/docs-release-notes"))
const Error403 = React.lazy(() => import("./pages/errors-403"))
const Error404 = React.lazy(() => import("./pages/errors-404"))
const Error500 = React.lazy(() => import("./pages/errors-500"))
const GridCodeLookup = React.lazy(() => import("./pages/gridcode-lookup"))
const LlmSchemaListCategories = React.lazy(() => import("./pages/llmschema-categories-list"))
const LlmSchemaColumnDetail = React.lazy(() => import("./pages/llmschema-column-detail"))
const LlmSchemaPreview = React.lazy(() => import("./pages/llmschema-preview"))
const LlmSchemaTableDetail = React.lazy(() => import("./pages/llmschema-table-detail"))
const LlmSchemaListTables = React.lazy(() => import("./pages/llmschema-tables-list"))
const PrecisionFetchBacklogDocument = React.lazy(
  () => import("./pages/precision-fetch-backlog-document"),
)
const PrecisionFetchBacklog = React.lazy(() => import("./pages/precision-fetch-backlog"))
const PrecisionFetchLibraryDocument = React.lazy(
  () => import("./pages/precision-fetch-library-document"),
)
const PrecisionFetchLibrary = React.lazy(() => import("./pages/precision-fetch-library"))
const PublicPrivacy = React.lazy(() => import("./pages/public-privacy"))
const PublicTermsOfService = React.lazy(() => import("./pages/public-terms"))
const TwingenieBacklogDetail = React.lazy(() => import("./pages/twingenie-backlog-detail"))
const TwingenieBacklog = React.lazy(() => import("./pages/twingenie-backlog"))
const AdminUserEdit = React.lazy(() => import("./pages/user-edit"))
const AdminUserList = React.lazy(() => import("./pages/user-list"))
const UserOnboarding = React.lazy(() => import("./pages/user-onboarding"))

export const routes = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error500 />,
    children: [
      // APP ROUTES
      {
        index: true,
        element: <Dashboard />,
        errorElement: <Error500 />,
      },
      {
        path: "onboarding",
        element: <UserOnboarding />,
        errorElement: <Error500 />,
      },
      {
        path: "agents",
        children: [
          {
            index: true,
            element: <AgentList />,
            errorElement: <Error500 />,
          },
          {
            path: "chat",
            // TODO: Add a "catch-all" route that checks to see if it is a slug or a 404
            children: [
              {
                path: ":agentId",
                element: <AgentChat />,
                errorElement: <Error500 />,
              },
              {
                path: ":agentId/:conversationId",
                element: <AgentChat />,
                errorElement: <Error500 />,
              },
              {
                path: ":agentId/:conversationId/:conversationType",
                element: <AgentChat />,
                errorElement: <Error500 />,
              },
            ],
          },
          {
            path: "manage",
            children: [
              {
                index: true,
                element: <AgentsManageList />,
                errorElement: <Error500 />,
              },
              {
                path: "create",
                errorElement: <Error500 />,
                element: <AgentsManageCreate />,
              },
              {
                path: "edit/:agentId",
                errorElement: <Error500 />,
                element: <AgentsManageEdit />,
              },
              {
                path: "context",
                element: <AgentsManageContext />,
                errorElement: <Error500 />,
              },
              {
                path: "reports",
                element: <AgentsManageReports />,
                errorElement: <Error500 />,
              },
              {
                path: "reports/:reportId",
                element: <AgentsManageReportDetail />,
                errorElement: <Error500 />,
              },
              {
                path: "knowledge",
                children: [
                  {
                    index: true,
                    element: <AgentsManageKnowledgeList />,
                    errorElement: <Error500 />,
                  },
                  {
                    path: "create",
                    errorElement: <Error500 />,
                    element: <AgentsManageKnowledgeCreate />,
                  },
                  {
                    path: "edit/:id",
                    errorElement: <Error500 />,
                    element: <AgentsManageKnowledgeEdit />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "gridcode",
        children: [
          {
            path: "lookup",
            children: [
              {
                index: true,
                errorElement: <Error500 />,
                element: <GridCodeLookup />,
              },
            ],
          },
        ],
      },
      {
        path: "precision-fetch",
        children: [
          {
            path: "library",
            children: [
              {
                index: true,
                errorElement: <Error500 />,
                element: <PrecisionFetchLibrary />,
              },
              {
                path: ":documentId",
                errorElement: <Error500 />,
                element: <PrecisionFetchLibraryDocument />,
              },
            ],
          },
          {
            path: "backlog",
            children: [
              {
                index: true,
                errorElement: <Error500 />,
                element: <PrecisionFetchBacklog />,
              },
              {
                path: ":backlogId",
                errorElement: <Error500 />,
                element: <PrecisionFetchBacklogDocument />,
              },
            ],
          },
          {
            path: "agent",
            children: [
              {
                index: true,
                element: <AgentChat />,
                errorElement: <Error500 />,
              },
            ],
          },
        ],
      },
      {
        path: "twingenie",
        children: [
          {
            path: "backlog",
            children: [
              {
                index: true,
                errorElement: <Error500 />,
                element: <TwingenieBacklog />,
              },
              {
                path: ":backlogId",
                errorElement: <Error500 />,
                element: <TwingenieBacklogDetail />,
              },
            ],
          },
          {
            path: "agent",
            children: [
              {
                index: true,
                element: <AgentChat />,
                errorElement: <Error500 />,
              },
            ],
          },
        ],
      },
      {
        path: "documents",
        children: [
          {
            index: true,
            element: <DocumentsList />,
            errorElement: <Error500 />,
          },
          {
            path: ":documentId",
            element: <DocumentDetail />,
            errorElement: <Error500 />,
          },
        ],
      },
      {
        path: "dynamic-tables",
        children: [
          {
            index: true,
            element: <DynamicTablesList />,
            errorElement: <Error500 />,
          },
          {
            path: "new",
            element: <DynamicTablesCreate />,
            errorElement: <Error500 />,
          },
          {
            path: ":id",
            element: <DynamicTablesDetail />,
            errorElement: <Error500 />,
          },
        ],
      },
      {
        path: "datalake",
        children: [
          {
            path: "tables",
            element: <LlmSchemaListTables />,
            errorElement: <Error500 />,
          },
          {
            path: "tables/:tableId",
            element: <LlmSchemaTableDetail />,
            errorElement: <Error500 />,
          },
          {
            path: "tables/:tableId/:columnId",
            element: <LlmSchemaColumnDetail />,
            errorElement: <Error500 />,
          },
          {
            path: "categories",
            element: <LlmSchemaListCategories />,
            errorElement: <Error500 />,
          },
          {
            path: "preview",
            element: <LlmSchemaPreview />,
            errorElement: <Error500 />,
          },
        ],
      },
      {
        path: "users",
        children: [
          {
            index: true,
            element: <AdminUserList />,
            errorElement: <Error500 />,
          },
          {
            path: ":userId",
            errorElement: <Error500 />,
            element: <AdminUserEdit />,
          },
        ],
      },
      {
        path: "docs",
        children: [
          {
            path: "release-notes",
            element: <DocsReleaseNotes />,
          },
        ],
      },
      // ----------------------------------------------------------------------
      // PUBLIC ROUTES
      {
        path: "auth",
        errorElement: <Error500 />,
        children: [
          {
            path: "login",
            element: <AuthLogin />,
            errorElement: <Error500 />,
          },
          {
            path: "code",
            element: <AuthCode />,
            errorElement: <Error500 />,
          },
          {
            // NOTE: This route has a different layout
            path: "callback",
            element: <AuthCallback />,
            errorElement: <Error500 />,
          },
        ],
      },
      {
        path: "errors",
        errorElement: <Error500 />,
        children: [
          {
            path: "403",
            element: <Error403 />,
            errorElement: <Error500 />,
          },
          {
            path: "500",
            element: <Error500 />,
          },
        ],
      },
      {
        path: "privacy-policy",
        element: <PublicPrivacy />,
        errorElement: <Error500 />,
      },
      {
        path: "terms-of-service",
        element: <PublicTermsOfService />,
        errorElement: <Error500 />,
      },
      // CATCH ALL ROUTE
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
]

export const ROOT_ROUTES = [
  "admin",
  "auth",
  "errors",
  "privacy",
  "terms",
  "chatbots",
  "chat",
  "datalake",
  "dynamic-tables",
]
