import { BeatLoader } from "react-spinners"

export function FullscreenLoadingOverlay() {
  return (
    <div className="bg-background fixed inset-0 z-50 flex items-center justify-center">
      <div className="flex flex-col items-center">
        <BeatLoader size={20} color="hsl(210 40% 98%)" />
      </div>
    </div>
  )
}
