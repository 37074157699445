import * as Sentry from "@sentry/react"
import React from "react"
import {
  createRoutesFromChildren,
  matchRoutes,
  createBrowserRouter as nativeCreateBrowserRouter,
  useLocation,
  useNavigationType,
} from "react-router"

if (import.meta.env.VITE_STAGE === "production") {
  Sentry.init({
    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    dsn: import.meta.env.VITE_SENTRY_DSN,
  })
}

export const createBrowserRouter =
  import.meta.env.VITE_STAGE === "production"
    ? Sentry.wrapCreateBrowserRouterV7(nativeCreateBrowserRouter)
    : nativeCreateBrowserRouter
