import { api } from "@/api"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { toast } from "sonner"

export function useOnboardingStatusQuery() {
  return useQuery({
    queryKey: ["users", "onboarding-status"],
    queryFn: () => api.users.getOnboardingStatus.query(),
  })
}

export function useSubmitOnboardingMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.users.submitOnboarding.mutate,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["users", "onboarding-status"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["users", "onboarding-data"],
        }),
      ])
      toast("Onboarding completed successfully")
    },
    onError: (error) => {
      console.error("Failed to submit onboarding data", error)
      toast("Failed to submit onboarding data", {
        description: error.message || "Please try again later",
      })
    },
  })
}
