import { useOnboardingStatusQuery } from "@/hooks/use-api-onboarding"
import { useEffect } from "react"
import { useNavigate } from "react-router"

type OnboardingCheckProps = {
  children: React.ReactNode
}

export function OnboardingCheck({ children }: OnboardingCheckProps) {
  const onboardingStatusQuery = useOnboardingStatusQuery()
  const navigate = useNavigate()

  useEffect(() => {
    // Only redirect if we have successfully fetched the onboarding status
    // and the user is not onboarded
    if (onboardingStatusQuery.data && !onboardingStatusQuery.data.isOnboarded) {
      navigate("/onboarding")
    }
  }, [onboardingStatusQuery.data, navigate])

  // Show nothing while we're loading
  if (onboardingStatusQuery.isLoading || !onboardingStatusQuery.data) {
    return null
  }

  // If user is onboarded, show the children
  if (onboardingStatusQuery.data.isOnboarded) {
    return <>{children}</>
  }

  // Should not reach here due to the redirect, but just in case
  return null
}
