import { FullscreenLoadingOverlay } from "@/components/fullscreen-loading-overlay"
import { NavSecondary, type SecondaryNavigationItem } from "@/components/nav-secondary"
import { NavUser } from "@/components/nav-user"
import {
  Navigation,
  type NavigationItem,
  type NavigationItemWithChildren,
} from "@/components/navigation"
import { OnboardingCheck } from "@/components/onboarding-check"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  useSidebar,
} from "@/components/ui/sidebar.tsx"
import { AnalyticsProvider } from "@/context/analytics.tsx"
import { useRequiredSession } from "@/context/auth.tsx"
import { useApiPrefetch } from "@/hooks/use-api-prefetch"
import { useIsMobile } from "@/hooks/use-mobile"
import { isInternalUser } from "@/lib/auth"
import {
  BookOpen,
  Bot,
  DatabaseIcon,
  FingerprintIcon,
  Grid3x3Icon,
  MessageCircleQuestionIcon,
  TargetIcon,
  UsersIcon,
} from "lucide-react"
import React from "react"
import { Link, Outlet, useLocation, useOutletContext } from "react-router"

export type AppLayoutContext = {
  setSidebar: (node: React.ReactNode) => void
}

type Navigation = {
  main: NavigationItem[]
  secondary: SecondaryNavigationItem[]
}

export function AppLayout() {
  const session = useRequiredSession()
  const location = useLocation()
  const isMobile = useIsMobile()

  // TODO: THis is legacy to support Chatbots, remove when can
  const [sidebar, setSidebar] = React.useState<React.ReactNode | undefined>(undefined)

  const prefetchComplete = useApiPrefetch()

  // This ensures we scroll to the top of each page after a navigation
  const mainElRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (mainElRef.current) {
      mainElRef.current.scrollIntoView({
        block: "start",
        behavior: "instant",
      })
    }
  }, [location.pathname])

  // Build the main navigation
  const navigation = React.useMemo<Navigation>(() => {
    const main: NavigationItem[] = []

    const agentsMenu: NavigationItemWithChildren = {
      title: "Agents",
      icon: Bot,
      defaultOpen: true,
      items: [
        {
          title: "Chat",
          url: "/agents",
        },
      ],
    }
    main.push(agentsMenu)
    if (
      isInternalUser(session.account) &&
      (session.account.role === "admin" || session.account.role === "superadmin")
    ) {
      agentsMenu.items.push(
        {
          title: "Manage Agents",
          url: "/agents/manage",
        },
        {
          title: "Knowledge Bases",
          url: "/agents/manage/knowledge",
        },
        {
          title: "Global Agent Context",
          url: "/agents/manage/context",
        },
        {
          title: "Reported Conversations",
          url: "/agents/manage/reports",
        },
      )
    }

    if (isInternalUser(session.account)) {
      main.push({
        title: "GRID Code",
        icon: Grid3x3Icon,
        defaultOpen: false,
        items: [{ title: "Lookup", url: "/gridcode/lookup" }],
      })

      main.push({
        title: "Precision Fetch",
        icon: TargetIcon,
        defaultOpen: false,
        items: [
          {
            title: "Library",
            url: "/precision-fetch/library",
          },
          {
            title: "Backlog",
            url: "/precision-fetch/backlog",
          },
        ],
      })

      main.push({
        title: "TwinGenie",
        icon: FingerprintIcon,
        defaultOpen: false,
        items: [{ title: "Backlog", url: "/twingenie/backlog" }],
      })
    }

    if (
      isInternalUser(session.account) &&
      (session.account.role === "admin" || session.account.role === "superadmin")
    ) {
      main.push({
        title: "Datalake",
        icon: DatabaseIcon,
        items: [
          {
            title: "Tables",
            url: "/datalake/tables",
          },
          {
            title: "Categories",
            url: "/datalake/categories",
          },
          {
            title: "LLM Schema Preview",
            url: "/datalake/preview",
          },
        ],
      })
    }

    if (isInternalUser(session.account) && session.account.role === "superadmin") {
      main.push({
        title: "Files",
        icon: BookOpen,
        items: [
          {
            title: "Documents",
            url: "/documents",
          },
        ],
      })
    }

    if (
      isInternalUser(session.account) &&
      (session.account.role === "admin" || session.account.role === "superadmin")
    ) {
      main.push({
        title: "Users",
        icon: UsersIcon,
        items: [
          {
            title: "Manage",
            url: "/users",
          },
        ],
      })
    }

    //if (isInternalUser(session.account)) {
    //  main.push({
    //    title: "Release Notes",
    //    icon: BookOpen,
    //    url: "/docs/release-notes",
    //  })
    //}

    return {
      main,
      secondary: [
        {
          title: "WhatsApp Support",
          url: "https://chat.whatsapp.com/H3WMFwSmXyY3XYVFkuNE0o",
          icon: MessageCircleQuestionIcon,
        },
      ],
    }
  }, [])

  return (
    <AnalyticsProvider>
      <OnboardingCheck>
        <SidebarProvider>
          <Sidebar variant="inset">
            <SidebarHeader>
              <SidebarMenu>
                <SidebarMenuItem>
                  <SidebarMenuButton size="lg" asChild>
                    <Link to="/">
                      <div className="bg-foreground text-sidebar-primary-foreground flex aspect-square size-8 items-center justify-center rounded-full">
                        <img src="/favicon.svg" alt="Logo" className="size-6" />
                      </div>
                      <div className="grid flex-1 text-left text-sm leading-tight">
                        <span className="truncate font-semibold">NetLife</span>
                        <span className="truncate text-xs">Artificial Intelligence</span>
                      </div>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarHeader>
            <SidebarContent>
              <Navigation title="Platform" items={navigation.main} />
              <NavSecondary items={navigation.secondary} className="mt-auto" />
            </SidebarContent>
            <SidebarFooter>
              <NavUser session={session} />
            </SidebarFooter>

            <div id="agent-chat-sidebar-portal" />
          </Sidebar>

          <SidebarInset
            className="relative m-0 mt-0 overflow-y-auto overflow-x-hidden"
            ref={mainElRef}
          >
            <Outlet context={{ setSidebar } satisfies AppLayoutContext} />
          </SidebarInset>
          {sidebar}

          {!prefetchComplete && <FullscreenLoadingOverlay />}

          {isMobile && <MobileAutoHideSidebar />}
        </SidebarProvider>
      </OnboardingCheck>
    </AnalyticsProvider>
  )
}

export function useAppLayoutContext() {
  return useOutletContext<AppLayoutContext>()
}

function MobileAutoHideSidebar() {
  const sidebar = useSidebar()
  const location = useLocation()

  React.useEffect(() => {
    sidebar.setOpenMobile(false)
  }, [location.pathname])

  return null
}
