export namespace QueryKeys {
  export const documents = {
    /**
     * Use this to clear all queries related to documents
     **/
    documents: () => ["documents"],
    documentsPage: (params?: any) => ["documents", "page", params],
    document: (id: string) => ["documents", "document", id],
    documentPages: (id: string, params?: any) => ["documents", "document-pages", id, params],
    documentPage: (id: string, pageNumber: number) => [
      "documents",
      "document-page",
      id,
      pageNumber,
    ],
  }

  export const chatbots = {
    chatbots: () => ["chatbots"],
    chatbotsList: () => ["chatbots", "list"],
    chatbotDetail: (chatbotId: string) => ["chatbots", "detail", chatbotId],
    knowledge: () => ["knowledge"],
    knowledgeList: () => ["knowledge", "list"],
    knowledgeGet: (knowledgeId: string) => ["knowledge", "get", knowledgeId],
    conversation: (chatbotId: string, conversationId?: string) => [
      "chatbots",
      "conversation",
      chatbotId,
      conversationId,
    ],
  }

  export const agents = {
    /**
     * Use this to clear all queries related to agents
     **/
    agentsRoot: () => ["agents"],
    agentsList: () => ["agents", "list"],
    agentsPage: (params: any) => ["agents", "page", params],
    agentById: (agentId: string) => ["agents", "agent", agentId],
    agentBySlug: (agentSlug: string) => ["agents", "by-slug", agentSlug],
    agentDetailById: (agentId: string) => ["agents", "detail-by-id", agentId],
    conversationsRoot: () => ["conversations"],
    conversationsListByAgentId: (agentId: string) => ["conversations", agentId],
    conversationDetailById: (conversationId: string) => ["conversation-detail", conversationId],
    /**
     * Use this to clear all queries related to agents knowledge bases
     **/
    knowledgeBasesRoot: () => ["knowledge-bases"],
    knowledgeBasesList: () => ["knowledge-bases", "list"],
    knowledgeBasesPage: (params: any) => ["knowledge-bases", "page", params],
    knowledgeBaseById: (id: string) => ["knowledge-bases", "detail", id],
    context: () => ["agents", "context"],
    reportsPage: (params?: any) => ["reports", "page", params],
    reportById: (reportId: string) => ["reports", "id", reportId],
  }

  export const dynamicTables = {
    /**
     * Use this to clear all queries related to dynamic tables
     **/
    root: () => ["dynamic-tables"],
    list: () => ["dynamic-tables", "list"],
    schema: (id: string) => ["dynamic-tables", "schema", id],
  }

  export const llmschema = {
    /**
     * Use this to clear all queries related to llmschema
     **/
    root: () => ["llmschema"],
    /**
     * Use this to clear all queries related to llmschema tables
     **/
    rootTables: () => ["llmschema", "tables"],
    tables: (params: any) => ["llmschema", "tables", params],
    tableDetail: (tableId: string) => ["llmschema", "tables", "table-details", tableId],
    tableDetails: (params: any) => ["llmschema", "tables", "table-details", params],
    tableColumns: (tableId: string, params: any) => [
      "llmschema",
      "tables",
      tableId,
      "columns",
      params,
    ],
    tableData: (tableId: string, options: any) => ["llmschema", "tables", tableId, "data", options],
    tableRelationships: (tableId: string) => ["llmschema", "tables", tableId, "relationships"],
    columnDetail: (columnId: string) => ["llmschema", "columns", "column-detail", columnId],
    categories: () => ["llmschema", "categories"],
    categoriesWithCounts: () => ["llmschema", "categories-with-counts"],
    categoryDetail: (categoryId: string) => ["llmschema", "category-detail", categoryId],
    export: () => ["llmschema", "export"],
  }

  export const precisionFetch = {
    backlogs: (filters?: Record<string, unknown>) => ["precision-fetch", "backlogs", filters],
    backlog: (id: string) => ["precision-fetch", "backlog", id],
    backlogPages: (backlogId: string, filters?: Record<string, unknown>) => [
      "precision-fetch",
      "backlog-pages",
      backlogId,
      filters,
    ],
    backlogDuplicates: (backlogId: string) => [
      "precision-fetch",
      "backlog",
      backlogId,
      "duplicates",
    ],
    documents: (params?: any) => ["precision-fetch", "documents", params],
    document: (id: string) => ["precision-fetch", "document", id],
    documentPages: (id: string, params: any) => ["precision-fetch", "document-pages", id, params],
    documentPageImages: (id: string) => ["precision-fetch", "document-page-images", id],
    advancedSearch: (params?: Record<string, unknown>) => [
      "precision-fetch",
      "advanced-search",
      params,
    ],
  }

  export const twingenie = {
    backlogs: (filters?: Record<string, unknown>) => ["twingenie:backlogs", filters],
    backlog: (id: string) => [`twingenie:backlog`, id],
    backlogDuplicates: (backlogId: string) => [`twingenie:backlog`, backlogId, "duplicates"],
    backlogLogs: (backlogId: string, filters?: Record<string, unknown>) => [
      "twingenie:backlog-logs",
      backlogId,
      filters,
    ],
    backlogDocumentReferences: (backlogId: string) => [
      `twingenie:backlog`,
      backlogId,
      "document-references",
    ],
    backlogDocumentPageReferences: (backlogId: string) => [
      `twingenie:backlog`,
      backlogId,
      "document-page-references",
    ],
    precisionFetchJob: (jobId: string) => ["precision-fetch:job", jobId],
  }

  export const datalake = {
    itemMasters: (filters?: Record<string, unknown>) => ["datalake:item-master", filters],
    itemMasterById: (id: number) => ["datalake:item-master-by-id", id],
  }

  export const users = {
    list: (filters?: Record<string, unknown>) => ["users:list", filters],
    user: (id: string) => ["users:user", id],
  }
}
