import { createTRPCClient, httpBatchLink } from "@trpc/client"
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server"

import { authHeaders, logout } from "@/context/auth.tsx"
import type { AppRouter } from "@ai/functions/api/handler"

export const api = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: import.meta.env.VITE_API_URL + "/trpc",
      headers: () => {
        return authHeaders()
      },
      fetch: async (input, init) => {
        const response = await fetch(input, init)
        if (response.status === 401) {
          logout()
        }
        if (response.status === 403) {
          window.location.href = "/errors/403"
        }
        if (response.status >= 500) {
          window.location.href = "/errors/500"
        }
        return response
      },
    }),
  ],
})

export type RouterInput = inferRouterInputs<AppRouter>

export type RouterOutput = inferRouterOutputs<AppRouter>
